import { Box, Button, FormControl, InputLabel, Typography } from '@mui/material'
import { type InferType } from 'yup'
import { type promotionSchema } from './PromotionsNewPage'
import { type promotionLicensedEstablishmentArraySchema } from 'utils/schemas/promotionSchema'
import { useFormContext, Controller } from 'react-hook-form'
import { ChooseLocationsModal } from './ChooseLocationsModal.tsx'
import { useState, useEffect } from 'react'

interface LocationSelectProps {
  defaultLocations:
    | Array<{
        promotionId: number
        licensedEstablishmentId: number
      }>
    | null
    | undefined
  defaultPromotionId: number
  showLabel: boolean
}

export const LocationSelect = ({
  defaultLocations,
  defaultPromotionId,
  showLabel,
  ...otherProps
}: LocationSelectProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { watch, control, setValue } =
    useFormContext<InferType<typeof promotionSchema>>()
  const licensedEstablishments = watch('licensedEstablishments')

  useEffect(() => {
    setValue('licensedEstablishments', defaultLocations ?? [])
  }, [])

  const saveLocations = (
    locations: InferType<typeof promotionLicensedEstablishmentArraySchema>
  ) => {
    setValue('licensedEstablishments', locations)
  }

  return (
    <FormControl>
      {showLabel ? (
        <InputLabel
          id={`licenseEstablishments-label`}
          htmlFor="licensedEstablishments"
          required={true}
        >
          Licensed Establishments
        </InputLabel>
      ) : null}
      <Box display="flex" alignItems="flex-end">
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          Choose Locations
        </Button>
        <Typography style={{ marginLeft: '10px' }}>
          {`${licensedEstablishments?.length} location(s) selected`}
        </Typography>
      </Box>
      <Controller
        name="licensedEstablishments"
        {...otherProps}
        control={control}
        defaultValue={[]}
        render={() => (
          <ChooseLocationsModal
            isModalOpen={modalOpen}
            toggleIsOpen={() => setModalOpen(false)}
            defaultLocations={
              defaultLocations
                ? defaultLocations.map(
                    (x) => `licensedEstablishment-${x.licensedEstablishmentId}`
                  )
                : []
            }
            defaultPromotionId={defaultPromotionId}
            saveLocations={saveLocations}
          />
        )}
      />
    </FormControl>
  )
}
