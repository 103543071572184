import { Box, Button } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { DataTable } from 'components/Shared/DataTable'
import { PageHeader } from 'components/Shared/PageHeader'
import { Colors, colorPrimitives } from 'components/Theme'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetPromotions } from 'hooks/api/Promotion/useGetPromotions'
import { type GridColDef, type GridEventListener } from '@mui/x-data-grid'
import {
  type PromotionStatus,
  calculatePromotionStatus,
  dataTablePromotionDateFormatter,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { useMemo, useState } from 'react'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { type Promotion } from 'types/api'
import { DeletePromotionsRenderCell } from './DeletePromotionsRenderCell'

const getColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Special & Event Name',
    minWidth: 380,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'Start'),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'End'),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      const startDate = params.row.startDate
        ? new Date(params.row.startDate)
        : null
      const endDate = params.row.endDate ? new Date(params.row.endDate) : null

      const promoStatusLabel = (status: PromotionStatus, isMobile: boolean) => {
        switch (status) {
          case 'scheduled':
            return isMobile ? 'Status: SCHEDULED' : 'SCHEDULED'
          case 'active':
            return isMobile ? 'Status: ACTIVE' : 'ACTIVE'
          case 'expired':
            return isMobile ? 'Status: EXPIRED' : 'EXPIRED'
          default:
            return ''
        }
      }

      const status = calculatePromotionStatus(startDate, endDate)
      return promoStatusLabel(status, isMobile)
    },
    renderCell: (params) => {
      const status = params.value
      const promoBackgroundColor: Record<string, string> = {
        ACTIVE: Colors.promotionsStatus.background.Active,
        EXPIRED: Colors.promotionsStatus.background.Expired,
        SCHEDULED: Colors.promotionsStatus.background.Scheduled,
      }

      return (
        <Box
          style={{
            backgroundColor: promoBackgroundColor[status],
            color: colorPrimitives.black,
            fontWeight: 'bold',
            borderRadius: '4px',
            padding: '6px',
            display: 'inline-block',
          }}
        >
          {status}
        </Box>
      )
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 215,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.type === 'Global') {
        return 'J&J Promo'
      } else if (params.row.type === 'Location') {
        return `${String(params.row.licensedEstablishments.length)} Location(s)`
      } else if (params.row.type === 'CorporateAccount') {
        const corporateAccount = params.row.corporateAccount
          ? params.row.corporateAccount
          : null
        if (corporateAccount) {
          return corporateAccount.name
        }
      } else if (params.row.type === 'Organization') {
        const organization = params.row.organization
          ? params.row.organization
          : null
        if (organization) {
          return organization.name
        }
      }
    },
  },
  {
    field: '__menu__',
    headerName: '',
    align: 'left',
    renderCell: (params) => {
      // Only display the option menu on the row if the status column is 'scheduled' or 'active'
      const startDate = params.row.startDate
        ? new Date(params.row.startDate)
        : null
      const endDate = params.row.endDate ? new Date(params.row.endDate) : null
      const status = calculatePromotionStatus(startDate, endDate)
      return status === 'scheduled' || status === 'active' ? (
        <DeletePromotionsRenderCell params={params} />
      ) : null
    },
  },
]

export const PromotionsPage = () => {
  const navigate = useNavigate()
  const query = useGetPromotions()
  const { isMobile } = useLayout()
  const [filteredData, setFilteredData] = useState<Promotion[]>()

  const handleFilteredData = (filteredData: Promotion[]) => {
    setFilteredData(filteredData)
  }

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      navigate(`/Promotions/${params.id}`)
    }
  }

  const sortedPromotions = useMemo(() => {
    if (!query.data) return []

    // Create a shallow copy of the promotions array
    const promotionsCopy = [...query.data]

    // Sort the copied array
    return promotionsCopy.sort((a, b) => {
      if (!a.startDate && !b.startDate) return 0
      if (!a.startDate) return -1
      if (!b.startDate) return 1

      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })
  }, [query.data])

  return (
    <Page
      header={
        <PageHeader
          title="Specials & Events"
          actionButton={
            <RouterLink to="/Promotions/New" className="gap-1">
              <Button
                variant="contained"
                sx={{
                  px: '16px',
                  width: { xs: 358, sm: 358, md: 220, lg: 220 },
                }}
              >
                Create New Special & Event
              </Button>
            </RouterLink>
          }
        />
      }
    >
      <GridSearchField<'FullPromotionDTO'>
        placeholder="Search Specials & Events"
        data={sortedPromotions}
        handleFilteredData={handleFilteredData}
        fullWidth
      />

      {query.isPending && <ActivityIndicator />}
      {query.isError && <p>An error occured.</p>}
      {!query.isError && query.data && (
        <DataTable
          columns={getColumns(isMobile)}
          rows={filteredData ?? sortedPromotions}
          onCellClick={selectCell}
        />
      )}
    </Page>
  )
}
