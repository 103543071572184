import { parse } from 'date-fns'
import { shouldValidateEndTime, validateEndTime } from 'utils/util'
import { date, number, string, object, array } from 'yup'

export const promotionNameSchema = string()
  .required('This field requires entry')
  .max(50)

export const promotionDescriptionSchema = string()
  .required('This field requires entry')
  .max(500)

export const promotionTypeSchema = string()
  .required('This field requires a selection')
  .notOneOf(['Select Special & Event type'], 'This field requires a selection')

export const promotionLicensedEstablishmentSchema = object().shape({
  promotionId: number().required(),
  licensedEstablishmentId: number().required(),
})

export const promotionLicensedEstablishmentArraySchema = array()
  .of(promotionLicensedEstablishmentSchema)
  .typeError('This field requires a selection')
  .when('type', {
    is: (val: string) => val.includes('Location Promotion'),
    then: (schema) => schema.min(1, 'At least one Location is required'),
  })

export const promotionCorporateAccountIdSchema = number()
  .default(0)
  .typeError('This field requires a selection')
  .when('type', {
    is: (val: string) => val.includes('Corporate Account Promotion'),
    then: () =>
      number()
        .required('This field requires a selection')
        .typeError('This field requires a selection')
        .transform((val) => (isNaN(val) ? undefined : val)),
  })

export const promotionOrganizationIdSchema = number()
  .default(0)
  .typeError('This field requires a selection')
  .when('type', {
    is: (val: string) => val.includes('Organization Promotion'),
    then: () =>
      number()
        .required('This field requires a selection')
        .typeError('This field requires a selection')
        .transform((val) => (isNaN(val) ? undefined : val)),
  })

export const promotionScheduledPromotionSchema = string()

export const promotionStartDateSchema = date().when('scheduledPromotion', {
  is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
  then: () =>
    date()
      .required('This field requires entry')
      .typeError('This field requires entry'),
})

export const promotionStartTimeSchema = string().when('scheduledPromotion', {
  is: (scheduledPromotion: string) => scheduledPromotion === 'Yes',
  then: () =>
    string()
      .required('This field requires entry')
      .matches(
        /^(0\d|1[0-2]):[0-5]\d [APap][mM]$/,
        'Invalid time format. Please enter a valid time.'
      ),
})

export const promotionEndDateSchema = date()
  .nullable()
  .typeError('This date is invalid')
  .transform((currentValue, originalValue, context) => {
    if (context.isType(currentValue)) {
      return currentValue
    }
    if (originalValue === '') {
      return null
    }
    if (originalValue === 'MM/DD/YYYY') {
      return null
    }
    try {
      parse(originalValue, 'MM/DD/YYYY', new Date())
    } catch (error) {
      return new Date('')
    }
    return currentValue
  })
  .when(
    ['scheduledPromotion', 'startDate'],
    ([scheduledPromotion, startDate], schema) => {
      if (
        scheduledPromotion &&
        startDate instanceof Date &&
        isFinite(+startDate)
      ) {
        return schema.min(startDate, 'End date should not be before start date')
      }
      return schema
    }
  )

export const promotionEndTimeSchema = string().when(
  ['startDate', 'startTime', 'endDate'],
  ([startDate, startTime, endDate], schema) => {
    return schema.test({
      name: 'endTime',
      message: 'This field is required',
      test: (value) => {
        const timeRegex = /^(0?[1-9]|1[0-2]):[0-5]\d (AM|PM)$/
        if (
          value &&
          endDate instanceof Date &&
          isFinite(+endDate) &&
          !timeRegex.test(value)
        ) {
          return false
        }

        if (endDate instanceof Date && value?.toString().length === 0) {
          return false
        }

        if (value && endDate instanceof Date && isFinite(+endDate)) {
          return (
            shouldValidateEndTime(startDate, startTime, endDate, value) &&
            validateEndTime(startDate, startTime, endDate, value)
          )
        }

        return true
      },
    })
  }
)
