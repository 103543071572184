import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { type FieldValues, useForm, FormProvider } from 'react-hook-form'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useApiClient } from 'hooks/useApiClient'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { TextField } from 'components/Shared/TextField'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { LocationSelect } from './LocationSelect'
import { SelectOrganization } from 'components/SelectOrganization'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'
import { useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { formatISO, parse } from 'date-fns'
import {
  promotionDescriptionSchema,
  promotionEndDateSchema,
  promotionEndTimeSchema,
  promotionLicensedEstablishmentArraySchema,
  promotionCorporateAccountIdSchema,
  promotionOrganizationIdSchema,
  promotionNameSchema,
  promotionScheduledPromotionSchema,
  promotionStartDateSchema,
  promotionStartTimeSchema,
  promotionTypeSchema,
} from 'utils/schemas/promotionSchema'

export const promotionSchema = object().shape({
  name: promotionNameSchema,
  description: promotionDescriptionSchema,
  type: promotionTypeSchema,
  licensedEstablishments: promotionLicensedEstablishmentArraySchema,
  corporateAccountId: promotionCorporateAccountIdSchema,
  organizationId: promotionOrganizationIdSchema,
  scheduledPromotion: promotionScheduledPromotionSchema,
  startDate: promotionStartDateSchema,
  startTime: promotionStartTimeSchema,
  endDate: promotionEndDateSchema,
  endTime: promotionEndTimeSchema,
})

export const PromotionsNewPage = () => {
  const formMethods = useForm({
    resolver: yupResolver(promotionSchema),
  })
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const [selectedPromotionType, setSelectedPromotionType] = useState(
    'Select Special & Event type'
  )
  const [selectedScheduledPromotion, setSelectedScheduledPromotion] =
    useState('No')
  const navigate = useNavigate()
  const { isMobile } = useLayout()

  const getType = (description: string) => {
    if (description === 'J&J Promotion') return 'Global'
    else if (description === 'Location Promotion') return 'Location'
    else if (description === 'Corporate Account Promotion') {
      return 'CorporateAccount'
    } else if (description === 'Organization Promotion') return 'Organization'
  }

  const createPromotion = useMutation({
    mutationKey: ['promotions/create'],
    mutationFn: async (data: FieldValues) => {
      await post('/admin/promotions', {
        params: { header: undefined },
        body: {
          title: data.name,
          description: data.description,
          type: getType(data.type),
          startDate: data.startDate
            ? formatISO(parse(data.startTime, 'hh:mm a', data.startDate))
            : null,
          endDate: data.endDate
            ? formatISO(parse(data.endTime, 'hh:mm a', data.endDate))
            : null,
          licensedEstablishments: data.licensedEstablishments
            ? data.licensedEstablishments
            : null,
          corporateAccountId:
            data.corporateAccountId !== 0 ? data.corporateAccountId : null,
          organizationId:
            data.organizationId !== 0 ? data.organizationId : null,
        },
      })
    },
    onSuccess: async () => {
      setMessage('Promotion has been created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate('/Promotions')
    },
    onError: async () => {
      setMessage('Error creating promotion', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const createPromotionWrapper = handleSubmit((data) => {
    createPromotion.mutate(data)
  })

  const handleTypeChange = (e: SelectChangeEvent) => {
    if (e.target.value === 'Location Promotion') {
      register('licensedEstablishments')
    } else {
      unregister('licensedEstablishments')
    }
    if (e.target.value === 'Corporate Account Promotion') {
      register('corporateAccountId')
    } else {
      unregister('corporateAccountId')
    }
    if (e.target.value === 'Organization Promotion') {
      register('organizationId')
    } else {
      unregister('organizationId')
    }
    setSelectedPromotionType(e.target.value)
  }

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Special & Event"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backPath="/Promotions"
          backText="Specials & Events"
          isSecondary={true}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={createPromotionWrapper} noValidate>
          <Grid item xs={12} pb={4}>
            {Object.keys(errors).length > 0 && (
              <Alert severity="error">Please correct errors to continue</Alert>
            )}
          </Grid>
          <Stack spacing={4} maxWidth={isMobile ? '90vw' : 500}>
            <Box>
              <TextField
                required
                error={isPresent(errors.name)}
                label="Title"
                placeholder="Enter Title"
                {...register('name')}
              />
              {errors.name?.message && (
                <FormFieldErrorMessage message={errors.name.message} />
              )}
            </Box>
            <Box>
              <TextField
                multiline
                rows={5}
                required
                error={isPresent(errors.description)}
                label="Special & Event Description"
                placeholder="Enter description of special & event"
                {...register('description')}
              />
              <Typography variant="body-3">500 characters max</Typography>
              {errors.description?.message && (
                <FormFieldErrorMessage message={errors.description.message} />
              )}
            </Box>
            <Stack direction="column" gap={1}>
              <InputLabel
                htmlFor={'promotionType'}
                required
                sx={isMobile ? { fontSize: '16px' } : {}}
              >
                Special & Event Type
              </InputLabel>
              <Select
                {...register('type', { required: true })}
                error={isPresent(errors.type)}
                inputProps={{ id: 'promotionType' }}
                required
                defaultValue="Select Special & Event type"
                value={selectedPromotionType}
                onChange={handleTypeChange}
              >
                <MenuItem value="Select Special & Event type">
                  Select Special & Event type
                </MenuItem>
                <MenuItem value="Location Promotion">
                  Location Promotion
                </MenuItem>
                <MenuItem value="Corporate Account Promotion">
                  Corporate Account Promotion
                </MenuItem>
                <MenuItem value="Organization Promotion">
                  Organization Promotion
                </MenuItem>
                <MenuItem value="J&J Promotion">J&J Promotion</MenuItem>
              </Select>
              {errors.type?.message && (
                <FormFieldErrorMessage message={errors.type.message} />
              )}
            </Stack>
            {selectedPromotionType === 'Location Promotion' && (
              <Stack direction="column" gap={1}>
                <LocationSelect
                  defaultLocations={[]}
                  defaultPromotionId={0}
                  showLabel={true}
                />
                {errors.licensedEstablishments?.message && (
                  <FormFieldErrorMessage
                    message={errors.licensedEstablishments.message}
                  />
                )}
              </Stack>
            )}
            {selectedPromotionType === 'Corporate Account Promotion' && (
              <Stack direction="column" gap={1}>
                <SelectCorporateAccount
                  {...register('corporateAccountId')}
                  label="Corporate Account"
                  required
                  fullWidth
                  corporateAccountIdKey="corporateAccountId"
                  placeholder="Select a corporate account"
                />
                {errors.corporateAccountId?.message && (
                  <FormFieldErrorMessage
                    message={errors.corporateAccountId.message}
                  />
                )}
              </Stack>
            )}
            {selectedPromotionType === 'Organization Promotion' && (
              <Stack direction="column" gap={1}>
                <SelectOrganization
                  label="Organization"
                  required
                  fullWidth
                  corporateAccountId={undefined}
                  showAll={true}
                  {...register('organizationId')}
                />
                {errors.organizationId?.message && (
                  <FormFieldErrorMessage
                    message={errors.organizationId.message}
                  />
                )}
              </Stack>
            )}
            <Stack direction="column" gap={1}>
              <InputLabel
                htmlFor={'scheduledBoolean'}
                sx={isMobile ? { fontSize: '16px' } : {}}
              >
                Do you want to schedule this Special & Event?
              </InputLabel>
              <Select
                {...register('scheduledPromotion', { required: true })}
                inputProps={{ id: 'scheduledBoolean' }}
                data-testid="scheduledBoolean"
                error={isPresent(errors.scheduledPromotion)}
                defaultValue="No"
                value={selectedScheduledPromotion}
                onChange={(e) => setSelectedScheduledPromotion(e.target.value)}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
              {errors.scheduledPromotion?.message && (
                <FormFieldErrorMessage
                  message={errors.scheduledPromotion.message}
                />
              )}
            </Stack>
            {selectedScheduledPromotion === 'Yes' && (
              <div>
                <Typography variant="body-2">
                  To run the promotion continuously, don&apos;t select an end
                  date and time.
                </Typography>
                <div>
                  <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                    <div style={{ flex: 1, width: '100%' }}>
                      <InputLabel
                        required
                        sx={isMobile ? { fontSize: '16px' } : {}}
                      >
                        Start Date
                      </InputLabel>
                      <HookableDateField
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            },
                            error: isPresent(errors.startDate),
                          },
                        }}
                        {...register('startDate')}
                      />
                      {errors.startDate?.message && (
                        <FormFieldErrorMessage
                          message={errors.startDate.message}
                        />
                      )}
                    </div>
                    <div style={{ flex: 1, width: '100%' }}>
                      <InputLabel
                        required
                        sx={isMobile ? { fontSize: '16px' } : {}}
                      >
                        Start Time
                      </InputLabel>
                      <HookableTimeField
                        {...register('startTime')}
                        slotProps={{
                          textField: {
                            required: true,
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            },
                            error: isPresent(errors.startTime),
                          },
                        }}
                        sx={{ width: '100%' }}
                      />
                      {errors.startTime?.message && (
                        <FormFieldErrorMessage
                          message={errors.startTime.message}
                        />
                      )}
                    </div>
                  </Stack>
                </div>
                <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel sx={isMobile ? { fontSize: '16px' } : {}}>
                      End Date
                    </InputLabel>
                    <HookableDateField
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endDate),
                        },
                      }}
                      {...register('endDate')}
                    />
                    {errors.endDate?.message && (
                      <FormFieldErrorMessage message={errors.endDate.message} />
                    )}
                  </div>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel sx={isMobile ? { fontSize: '16px' } : {}}>
                      End Time
                    </InputLabel>
                    <HookableTimeField
                      {...register('endTime')}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endTime),
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.endTime?.message && (
                      <FormFieldErrorMessage message={errors.endTime.message} />
                    )}
                  </div>
                </Stack>
              </div>
            )}
            {isMobile && (
              <Grid container spacing={2} maxWidth="90vw">
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: 0, paddingRight: '8px' }}
                >
                  <Button variant="outlined" fullWidth>
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: '8px', paddingRight: 0 }}
                >
                  <RouterLink to="/Promotions">
                    <Button type="submit" variant="contained" fullWidth>
                      Create
                    </Button>
                  </RouterLink>
                </Grid>
              </Grid>
            )}
            {!isMobile && (
              <Stack direction="row" spacing={2}>
                <RouterLink to="/Promotions">
                  <Button variant="outlined">Cancel</Button>
                </RouterLink>
                <Button type="submit" variant="contained">
                  Create
                </Button>
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
