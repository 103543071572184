import { DataTable } from 'components/Shared/DataTable'
import { Box } from '@mui/material'
import { type GridEventListener, type GridColDef } from '@mui/x-data-grid'
import { colorPrimitives, Colors } from 'components/Theme'
import {
  calculatePromotionStatus,
  dataTablePromotionDateFormatter,
  type PromotionType,
  type PromotionStatus,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { useGetPromotions } from 'hooks/api/Promotion/useGetPromotions'
import { useMemo } from 'react'

const getColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Special & Event Name',
    minWidth: 380,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'Start'),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'End'),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      const startDate = params.row.startDate
        ? new Date(params.row.startDate)
        : null
      const endDate = params.row.endDate ? new Date(params.row.endDate) : null

      const promoStatusLabel = (status: PromotionStatus, isMobile: boolean) => {
        switch (status) {
          case 'scheduled':
            return isMobile ? 'Status: SCHEDULED' : 'SCHEDULED'
          case 'active':
            return isMobile ? 'Status: ACTIVE' : 'ACTIVE'
          case 'expired':
            return isMobile ? 'Status: EXPIRED' : 'EXPIRED'
          default:
            return ''
        }
      }

      const status = calculatePromotionStatus(startDate, endDate)
      return promoStatusLabel(status, isMobile)
    },
    renderCell: (params) => {
      const status = params.value
      const promoBackgroundColor: Record<string, string> = {
        ACTIVE: Colors.promotionsStatus.background.Active,
        EXPIRED: Colors.promotionsStatus.background.Expired,
        SCHEDULED: Colors.promotionsStatus.background.Scheduled,
      }

      return (
        <Box
          style={{
            backgroundColor: promoBackgroundColor[status],
            color: colorPrimitives.black,
            fontWeight: 'bold',
            borderRadius: '4px',
            padding: '6px',
            display: 'inline-block',
          }}
        >
          {status}
        </Box>
      )
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 215,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.type === 'Global') {
        return 'J&J Promo'
      } else if (params.row.type === 'Location') {
        return `${String(params.row.licensedEstablishments.length)} Location(s)`
      } else if (params.row.type === 'CorporateAccount') {
        const corporateAccount = params.row.corporateAccount
          ? params.row.corporateAccount
          : null
        if (corporateAccount) {
          return corporateAccount.name
        }
      } else if (params.row.type === 'Organization') {
        const organization = params.row.organization
          ? params.row.organization
          : null
        if (organization) {
          return organization.name
        }
      }
    },
  },
]

interface PromotionsPageSectionProps {
  type: PromotionType
  id: number
}

export const PromotionsPageSection = ({
  type,
  id,
}: PromotionsPageSectionProps) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()
  const query = useGetPromotions()

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      navigate(`/Promotions/${params.id}`)
    }
  }

  const sortedPromotions = useMemo(() => {
    if (!query.data) return []

    // Create a shallow copy of the promotions array
    const promotionsCopy = [
      ...query.data.filter(
        (f) =>
          (type === 'Global' && f.type === 'Global') ||
          (type === 'CorporateAccount' && f.corporateAccountId === id) ||
          (type === 'Organization' && f.organizationId === id) ||
          (type === 'Location' &&
            f.licensedEstablishments?.some(
              (item) => item.licensedEstablishmentId === id
            ))
      ),
    ]

    // Sort the copied array
    return promotionsCopy.sort((a, b) => {
      if (!a.startDate && !b.startDate) return 0
      if (!a.startDate) return -1
      if (!b.startDate) return 1

      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })
  }, [query.data])

  return (
    <DataTable
      columns={getColumns(isMobile)}
      rows={sortedPromotions}
      onCellClick={selectCell}
    />
  )
}
