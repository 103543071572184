import { yupResolver } from '@hookform/resolvers/yup'
import {
  applyPhoneMask,
  applyDateMask,
  getPhoneMaskRegex,
  isPresent,
} from '@jjvgaming/player-payback-library'
import { Alert, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { ActivityButton } from 'components/ActivityButton'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage/FormFieldErrorMessage'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { format, parseISO } from 'date-fns'
import { usePutPatron } from 'hooks/api/Patron/usePutPatron'
import { usePostPatronNote } from 'hooks/api/usePostPatronNote'
import { useLayout } from 'hooks/useLayout'
import { type ApiError } from 'openapi-typescript-fetch'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import type { Patron, GenderType } from 'types/api'
import { stateAbbreviations } from 'utils/constants/stateAbbreviations'
import { object, string } from 'yup'

const PatronInformationSchema = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  phoneNumber: string()
    .matches(getPhoneMaskRegex, {
      message: 'Please enter a valid phone number',
    })
    .required('Phone number is required'),
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  dob: string().required('Birthdate is required'),
  gender: string<GenderType>().required(),
  address1: string(),
  address2: string(),
  city: string(),
  state: string(),
  zip: string(),
  note: string(),
})

const applyZipMask = (zip: string) => {
  const maskedValue = zip.replace(/[^\d-]/g, '')
  return maskedValue.substring(0, 10) // Limit to 10 characters (xxxxx-xxxx)
}

export const EditPatronInformation = ({
  isModalOpen,
  toggleModalIsOpen,
  patron,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  patron: Patron
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '85%'
  const formMethods = useForm({
    resolver: yupResolver(PatronInformationSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const dob = patron.dob ? parseISO(patron.dob) : ''
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const putPatronMutation = usePutPatron({
    onSuccess: (result) => {
      setSnackbarMessage(
        `${result.firstName} ${result.lastName} has been updated.`
      )
      toggleModalIsOpen()
    },
    onError: (error: ApiError) => {
      if (typeof error.data === 'string') {
        if (error.data.includes('PhoneNumber')) {
          control.setError('phoneNumber', { message: error.data })
        }
        if (error.data.includes('Email')) {
          control.setError('email', { message: error.data })
        }
      } else {
        setSnackbarMessage(`Error: Cannot save Patron information`, 'error')
      }
    },
  })

  const usePostPatronNoteMutation = usePostPatronNote({
    patronId: patron.id,
    onError: () =>
      setSnackbarMessage(`Error: Cannot save Patron note`, 'error'),
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    if (!patron.uuid) {
      setSnackbarMessage(`Error: invalid uuid`, 'error')
      return
    }

    const currentAddress = patron.addresses ? patron.addresses[0] : null

    putPatronMutation.mutate({
      uuid: patron.uuid,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      dob: formData.dob,
      gender: formData.gender,
      phoneNumber: formData.phoneNumber,
      id: patron.id,
      addresses: [
        {
          id: currentAddress ? currentAddress.id : 0,
          patronId: patron.id,
          address1: formData.address1 ? formData.address1 : null,
          address2: formData.address2 ? formData.address2 : null,
          city: formData.city ? formData.city : null,
          state: formData.state ? formData.state : null,
          postalCode: formData.zip ? `${formData.zip}` : null,
        },
      ],
      exclude: patron.exclude ?? undefined,
      excludeDate: patron.excludeDate ?? undefined,
      excludeReason: patron.excludeReason ?? undefined,
      deactivatedReason: patron.deactivatedReason ?? undefined,
    })

    if (formData.note) {
      usePostPatronNoteMutation.mutate({
        patronId: patron.id,
        note: formData.note,
      })
    }
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <form>
        <ModalFullHeader
          toggleIsOpen={toggleModalIsOpen}
          title={(patron.firstName ?? '') + ' ' + (patron.lastName ?? '')}
          subTitle={'Patron ID #' + JSON.stringify(patron.id)}
        />
        <ModalFullBody heightModal={heightModal}>
          <div className="container flex flex-col">
            {!!Object.keys(errors).length && (
              <Alert severity="error">Please correct errors to continue</Alert>
            )}
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                First Name
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="First Name"
                  {...register('firstName')}
                  defaultValue={patron.firstName}
                  error={isPresent(errors.firstName)}
                />
                {errors.firstName?.message && (
                  <FormFieldErrorMessage message={errors.firstName.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                Last Name
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="Last Name"
                  {...register('lastName')}
                  defaultValue={patron.lastName}
                  error={isPresent(errors.lastName)}
                />
                {errors.lastName?.message && (
                  <FormFieldErrorMessage message={errors.lastName.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                Phone Number
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={applyPhoneMask(
                    JSON.stringify(patron.phoneNumber)
                  )}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={isPresent(errors.phoneNumber)}
                      placeholder="Phone number"
                      {...register('phoneNumber')}
                      onChange={(phoneNumber) => {
                        onChange(
                          applyPhoneMask(String(phoneNumber.target.value))
                        )
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                {errors.phoneNumber?.message && (
                  <FormFieldErrorMessage message={errors.phoneNumber.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                Email
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="Email"
                  {...register('email')}
                  defaultValue={patron.email}
                  error={isPresent(errors.email)}
                />
                {errors.email?.message && (
                  <FormFieldErrorMessage message={errors.email.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                Birthdate
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <Controller
                  name="dob"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={isPresent(errors.dob)}
                      placeholder="Birthdate"
                      {...register('dob')}
                      defaultValue={dob ? format(dob, 'P') : null}
                      onChange={(dob) => {
                        onChange(applyDateMask(String(dob.target.value)))
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                {errors.dob?.message && (
                  <FormFieldErrorMessage message={errors.dob.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }} required>
                Gender
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <Select
                  fullWidth
                  placeholder="Gender"
                  {...register('gender')}
                  defaultValue={patron.gender}
                  error={isPresent(errors.gender)}
                >
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="PreferNotToAnswer">
                    Prefer Not To Answer
                  </MenuItem>
                </Select>
                {errors.gender?.message && (
                  <FormFieldErrorMessage message={errors.gender.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Address Line 1
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="Address Line 1"
                  {...register('address1')}
                  defaultValue={
                    patron.addresses?.length ? patron.addresses[0].address1 : ''
                  }
                  error={isPresent(errors.address1)}
                />
                {errors.address1?.message && (
                  <FormFieldErrorMessage message={errors.address1.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Address Line 2
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="Address Line 2"
                  {...register('address2')}
                  defaultValue={
                    patron.addresses?.length ? patron.addresses[0].address2 : ''
                  }
                  error={isPresent(errors.address2)}
                />
                {errors.address2?.message && (
                  <FormFieldErrorMessage message={errors.address2.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                City
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  fullWidth
                  placeholder="City"
                  {...register('city')}
                  defaultValue={
                    patron.addresses?.length ? patron.addresses[0].city : ''
                  }
                  error={isPresent(errors.city)}
                />
                {errors.city?.message && (
                  <FormFieldErrorMessage message={errors.city.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                State
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <Select
                  fullWidth
                  placeholder="State"
                  {...register('state')}
                  defaultValue={
                    patron.addresses?.length ? patron.addresses[0].state : ''
                  }
                  error={isPresent(errors.state)}
                >
                  {stateAbbreviations.map((stateAbbreviation) => {
                    return (
                      <MenuItem
                        value={stateAbbreviation}
                        key={stateAbbreviation}
                      >
                        {stateAbbreviation}
                      </MenuItem>
                    )
                  })}
                </Select>
                {errors.state?.message && (
                  <FormFieldErrorMessage message={errors.state.message} />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Zip
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <Controller
                  name="zip"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={isPresent(errors.zip)}
                      placeholder="Zip"
                      {...register('zip')}
                      defaultValue={
                        patron.addresses?.length
                          ? patron.addresses[0].postalCode
                          : ''
                      }
                      onChange={(zip) => {
                        onChange(applyZipMask(String(zip.target.value)))
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                {errors.zip?.message && (
                  <FormFieldErrorMessage message={errors.zip.message} />
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Note
              </InputLabel>
              <div className="md:w-1/2 flex flex-col">
                <TextField
                  inputProps={{ maxLength: 500 }}
                  multiline={true}
                  minRows={2}
                  fullWidth
                  placeholder="Note"
                  {...register('note')}
                  error={isPresent(errors.note)}
                />
                {errors.note?.message && (
                  <FormFieldErrorMessage message={errors.note.message} />
                )}
              </div>
            </div>
          </div>
        </ModalFullBody>
        <ModalFullFooter>
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            variant="contained"
            active={putPatronMutation.isPending}
            onClick={handleSubmitWrapper}
          >
            Save
          </ActivityButton>
        </ModalFullFooter>
      </form>
    </Modal>
  )
}
